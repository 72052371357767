import { postRequest, getRequest } from './index'
import { BSERURL } from '@/config'
import axios from 'axios'
// 获取验证码
export const getCodes = (successCallback, failureCallback) => {
  getRequest(`${BSERURL}/sys/captcha`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发送手机验证码
export const sendPhoneCode = (phone, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/cmp/sendCode?phone=` + phone, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户注册呀
export const register = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/cmp/register`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户账号密码登录呀
export const login = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/cmp/login`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 用户手机号码登录呀
export const phoneLogin = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/cmp/phone/login`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发送验证码登录呀
export const sendCodeLogin = (phone, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/cmp/sendCode/login?phone=` + phone, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取用户信息
export const getSysGetUserInfo = (successCallback, failureCallback) => {
  getRequest(`${BSERURL}/sys/getUserInfo`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 退出登录接口
export const loginOut = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/sys/logout`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合同模板ID获取内容
export const compactServiceGetContent = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/compactService/getContent`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 生成验证码图片
export const generateCaptchaImage = (data) => {
  return new Promise((resolve, reject) => {
    postRequest(`${BSERURL}sys/generateCaptchaImage`, data, res => {
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}

// 校验验证码图片
export const validCaptchaImage = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${BSERURL}sys/validCaptchaImage`, data).then((res) => {
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}
